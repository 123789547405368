// extracted by mini-css-extract-plugin
export var arGradientBlue = "ArTextarea-module--ar-gradient-blue--2T8Hy";
export var arGradientLight = "ArTextarea-module--ar-gradient-light--zjve1";
export var arH1 = "ArTextarea-module--ar-h1--qcADU";
export var arH1Mobile = "ArTextarea-module--ar-h1-mobile--fi9Ra";
export var arH2 = "ArTextarea-module--ar-h2--ROXTa";
export var arH2Mobile = "ArTextarea-module--ar-h2-mobile--0YNoY";
export var arH3 = "ArTextarea-module--ar-h3--rzd7k";
export var arH3Mobile = "ArTextarea-module--ar-h3-mobile--SHq2Q";
export var arH4 = "ArTextarea-module--ar-h4--2barF";
export var arH4Mobile = "ArTextarea-module--ar-h4-mobile--tzTNy";
export var arH5 = "ArTextarea-module--ar-h5--l6laK";
export var arH5Mobile = "ArTextarea-module--ar-h5-mobile--BXd2N";
export var arLinkDesktop = "ArTextarea-module--ar-link-desktop--+P+-S";
export var arLinkMobile = "ArTextarea-module--ar-link-mobile--z7oig";
export var arParagraphBase = "ArTextarea-module--ar-paragraph-base--V7ydb";
export var arParagraphBaseMedium = "ArTextarea-module--ar-paragraph-base-medium--CHEgA";
export var arParagraphSmall = "ArTextarea-module--ar-paragraph-small--0EgUt";
export var arParagraphSmallMedium = "ArTextarea-module--ar-paragraph-small-medium--ltyrn";
export var arParagraphXl = "ArTextarea-module--ar-paragraph-xl--h9Jql";
export var arParagraphXlMedium = "ArTextarea-module--ar-paragraph-xl-medium--+IAlk";
export var arParagraphXsmall = "ArTextarea-module--ar-paragraph-xsmall--BmRoj";
export var arParagraphXsmallMedium = "ArTextarea-module--ar-paragraph-xsmall-medium--cdLEs";
export var arQuote = "ArTextarea-module--ar-quote--C3BaG";
export var arQuoteMobile = "ArTextarea-module--ar-quote-mobile--dGtUp";
export var arTransition = "ArTextarea-module--ar-transition--mAAux";
export var container = "ArTextarea-module--container--Cqaa4";
export var error = "ArTextarea-module--error--TNK4Z";
export var hasValue = "ArTextarea-module--hasValue--k2D8T";
export var inputWrapper = "ArTextarea-module--inputWrapper--TnbcX";